<template lang="pug">
  .form-partial
    .form-row(v-for="key in partialKeys")
      .form-row-label
        FormFieldLabel(:title="$t(`${prefix}.${key}`)")
      template(v-if="isArray(item[key])")
        .form-row-immutable-field(v-for="valueItem in item[key]")
          span {{ valueItem }}
      template(v-else)
        .form-row-immutable-field
          span {{ item[key] }}
</template>

<script>
  // misc
  import { keys, isArray } from "lodash-es"

  export default {
    components: {
      Title: () => import("@/components/CompanySystem/Title"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    props: {
      prefix: {
        type: String,
        required: true
      },
      item: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      partialKeys() {
        return keys(this.item)
      }
    },

    methods: {
      isArray
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .form-row
    display: flex
    flex-direction: column
    margin: 0
    padding: 15px 0px

    &-label
      color: $default-gray
      margin-bottom: 5px
</style>
